import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { useEffect, useRef, useState } from "react"
import TheForm from "../components/form"
import Illustre1 from "../components/illustre1"
import Illustre2 from "../components/illustre2"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TeamPic from "../components/teampic"
import arrow from "../images/arrow-right.svg"
import logo from "../images/logo.svg"
import phone from "../images/phone.svg"
import piscine_icon from "../images/piscine_icon.jpg"
import toiture_icon from "../images/toiture_icon.jpg"
import villa_icon from "../images/villa_icon.jpg"
//import team_light from "../images/team_light.jpg"

const IndexPage = () => {
  const { t } = useTranslation()
  const gallery = useStaticQuery(graphql`
    query {
      salon_renovation: file(
        relativePath: { eq: "gallery/salon-renovation.jpg" }
      ) {
        childImageSharp {
          id
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      piscine_villa: file(relativePath: { eq: "gallery/piscine-villa.jpg" }) {
        childImageSharp {
          id
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      salon_villa: file(relativePath: { eq: "gallery/salon-villa.jpg" }) {
        childImageSharp {
          id
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      toits_renovation: file(
        relativePath: { eq: "gallery/toits-renovation.jpg" }
      ) {
        childImageSharp {
          id
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const slides = useRef(null)

  const [slideStep, setSlideStep] = useState(1)
  const [slideStepW, setSlideStepW] = useState(0)
  const [slideNb, setSlideNb] = useState(0)

  const initSlide = () => {
    const element = slides.current
    setSlideNb(element.children.length)
    setSlideStepW(element.scrollWidth / element.children.length)
  }

  useEffect(() => {
    initSlide()
    window.addEventListener("resize", initSlide)
  }, [slides])

  const handleScroll = e => {
    setSlideStep(Math.ceil((slides.current.scrollLeft + 1) / slideStepW))
  }

  return (
    <Layout>
      <SEO title={t("heading")} description={t("description")} />
      <section className="fluid">
        <div id="intro">
          <div>
            <img src={logo} id="logo" alt="acces construction" />
            <h1>{t("description")}</h1>
            <p>{t("- the enterprise")}</p>
            <a href="#contact" className="cta outline">
              {t("contact us")}
            </a>
          </div>
          <div>
            <Illustre1></Illustre1>
          </div>
        </div>
      </section>
      <section id="chantiers" className="fluid">
        <svg
          className="bg fluid"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="white" points="0,0 0,100 100,0" />
        </svg>

        <div>
          <h2>
            {t("projects")}
            <span id="slideStep">
              {slideStep} / {slideNb} <img src={arrow} alt="" />
            </span>
          </h2>
          <div id="gallery">
            <div className="slides" onScroll={handleScroll} ref={slides}>
              {Object.keys(gallery)
                .filter(imgName => imgName !== "team")
                .map(imgName => (
                  <Img
                    fluid={gallery[imgName].childImageSharp.fluid}
                    alt={"Acces construction" + imgName}
                    key={gallery[imgName].childImageSharp.id}
                  />
                ))}
            </div>
          </div>
          <div id="slideNav">
            {Array.from(Array(slideNb)).map((_, i) => (
              <span
                className={i === slideStep - 1 ? "active" : ""}
                key={i}
              ></span>
            ))}
          </div>
        </div>
        <svg
          className="bg fluid"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="white" points="0,100 100,0 100,100" />
        </svg>
      </section>
      <section id="services">
        <h2>{t("services")}</h2>
        <div>
          <article>
            <img src={piscine_icon} alt="" />
            <h3>{t("pools")}</h3>
            <p>{t("- before summer")}</p>
          </article>
          <article>
            <img src={villa_icon} alt="" />
            <h3>{t("modern house")}</h3>
            <p>{t("- personalised support")}</p>
          </article>
          <article>
            <img src={toiture_icon} alt="" />
            <h3>{t("roofs")}</h3>
            <p>{t("- roofer")}</p>
          </article>
        </div>
      </section>
      <section id="contact" className="fluid">
        <div>
          <div className="direct">
            <div>
              <Illustre2></Illustre2>
            </div>
            <div>
              <h2>{t("contact us")}</h2>
              <a href="tel:+33675934043‬" id="callBtn" className="cta outline">
                <img src={phone} alt="" />
                <span>+33 6 75 93 40 43‬</span>
              </a>
              <a href="mailto:mail@accesconstruction.com">
                mail@accesconstruction.com
              </a>
            </div>
          </div>
          <div className="rappel">
            <TheForm />
          </div>
        </div>
        <div id="team">
          <svg
            className="bg fluid"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon fill="white" points="0,0 0,100 100,0" />
          </svg>
          {/*<img src={team_light} className="fluid" alt={t("the team")} />

          <Img
            fluid={gallery["team"].childImageSharp.fluid}
            alt={t("the team")}
            className="fluid"
          />*/}
          <TeamPic alt={t("the team")}></TeamPic>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

/**
 * 
 * 
 *       alt={t("the team")}
      className="fluid"
      team: file(relativePath: { eq: "team_light.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
 */
