import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Illustre2 = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "terrasse-renovation.jpg" }) {
        childImageSharp {
          fixed(width: 503, height: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <div id="illustre2Img">
      <svg width="500" height="500" viewBox="0 0 500 525" className="border">
        <polygon
          fill="#F33434"
          points="250 0 500 208.418123 420.77255 525 79.2274505 525 0 208.418123"
        ></polygon>
      </svg>

      <Img
        fixed={data.file.childImageSharp.fixed}
        alt="Acces construction renovation terrasse"
      />

      <svg>
        <defs>
          <clipPath id="illustre2Mask">
            <polygon
              fill="#F33434"
              points="250 0 500 208.418123 420.77255 525 79.2274505 525 0 208.418123"
            ></polygon>
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default Illustre2
