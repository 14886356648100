import React, { useState } from "react"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import logo from "../images/logo_icon.png"

const TheForm = () => {
  const { language } = useI18next()
  const { t } = useTranslation()
  const [states, setStates] = useState({
    nom: "",
    tel: "",
    langue: language,
  })
  const [formSent, setFormSent] = useState(false)
  const [error, setError] = useState(false)

  const encode = data =>
    Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join("&")

  const handleChange = e => {
    const target = e.target
    const name = target.name
    setError(false)
    setStates({ ...states, [name]: e.target.value })
  }

  const handleSubmit = e => {
    const form = e.target
    e.preventDefault()
    if (!states.nom || !states.tel) {
      return setError(true)
    }
    const body = encode({
      "form-name": form.getAttribute("name"),
      ...states,
    })
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body,
    })
      .then(() => setFormSent(true))
      .catch(error => alert(error))
  }

  return (
    <div id="recall">
      {formSent ? (
        <div id="form_sent">
          <img src={logo} alt="" />
          <h3>
            {t("thank you")} {states.nom}
          </h3>
          <p>{t("callback at")}</p>
          <strong>{states.tel}</strong>
        </div>
      ) : (
        ""
      )}
      <h2>{t("callmeback")}</h2>
      <form
        onSubmit={handleSubmit}
        data-netlify="true"
        name="contact"
        method="post"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <input
          type="text"
          name="nom"
          placeholder="Nom"
          value={states.nom}
          onChange={handleChange}
          required
        />

        <input
          type="tel"
          name="tel"
          placeholder="Téléphone"
          value={states.tel}
          onChange={handleChange}
          required
          autoComplete="tel"
          inputMode="tel"
        />

        <input type="submit" value={t("callmeback")} className="cta" />
        {error ? (
          <div id="form_error">
            <span>{t("formerror")}</span>
          </div>
        ) : (
          ""
        )}
      </form>
    </div>
  )
}

export default TheForm
